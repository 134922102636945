<template>
  <section id="faq">
    <!-- seach banner  -->
    <v-card
      flat
      class="faq-bg d-flex align-center justify-center text-center mb-9"
    >
      <v-card-text>
        <p class="faq-title text-2xl font-weight-semibold primary--text mb-2">
          Hello, how can we help?
        </p>
        <p>or choose a category to quickly find the help you need</p>

        <v-form class="faq-search-input mx-auto">
          <v-text-field
            v-model="search"
            outlined
            placeholder="Ask a question...."
            hide-details
            class="faq-search-input"
            @change="searchHandler($event)"
          >
            <template #prepend-inner>
              <v-icon
                size="23"
                class="mx-1"
              >
                {{ icons.mdiMagnify }}
              </v-icon>
            </template>
          </v-text-field>
        </v-form>
      </v-card-text>
    </v-card>

    <v-card-text class="question-section">
      <v-row>
        <v-col
          v-for="(item,index) in faqQiestions"
          :key="index"
          md="10"
          offset-md="2"
          cols="12"
          class="mx-auto mb-8"
        >
          <div class="faq-title d-flex align-center">
            <v-avatar
              color="secondary"
              rounded
              size="40"
              class="v-avatar-light-bg"
            >
              <v-icon
                size="24"
                class="text--primary"
              >
                {{ icons.mdiHelpCircleOutline }}
              </v-icon>
            </v-avatar>

            <div class="ms-4">
              <h2 class="text--primary text-xl font-weight-medium">
                {{ index }}
              </h2>
            </div>
          </div>

          <div class="faq-question-answers mt-6">
            <v-expansion-panels>
              <v-expansion-panel
                v-for="data in item"
                :key="data.title"
              >
                <v-expansion-panel-header class="text-base">
                  {{ data.title }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <!-- eslint-disable vue/no-v-html -->
                  <div v-html="data.content">
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-col>

        <!-- no result found -->
        <v-col
          v-show="!faqQuestions.length"
          cols="12"
          class="text-center"
        >
          <h4 class="mt-4">
            Search result not found!!
          </h4>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text class="text-center">
      <p class="text-xl font-weight-medium text--primary mb-1">
        You still have a question?
      </p>
      <p>If you cannot find a question in our FAQ, you can always contact us. We will answer to you shortly!</p>
    </v-card-text>

    <v-row class="mx-auto">
      <v-col
        v-for="(contact,index) in contactDetails"
        :key="contact.icon"
        cols="12"
        sm="6"
        md="5"
        :class="index > 0 ? 'me-auto': 'ms-auto'"
      >
        <v-card
          class="faq-contact text-center pa-5"
          flat
        >
          <v-avatar
            rounded
            color="secondary"
            size="38"
            class="v-avatar-light-bg mb-6"
          >
            <v-icon
              class="text--primary"
              size="24"
            >
              {{ contact.icon }}
            </v-icon>
          </v-avatar>

          <p class="text-xl text--primary font-weight-medium mb-2">
            {{ contact.contact }}
          </p>

          <p class="text-sm mb-0">
            {{ contact.tagLine }}
          </p>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>

<script>
/* eslint-disable operator-linebreak */

import {
  mdiMagnify,
  mdiHelpCircleOutline,
  mdiCurrencyUsd,
  mdiBriefcaseVariantOutline,
  mdiPhone,
  mdiEmailOutline,
} from '@mdi/js'
import { ref, onMounted, watch } from '@vue/composition-api'

export default {
  setup(props, context) {
    const faqQiestions = ref({})
    const faqQuestions = ref([])

    const search = ref('')
    const listFaq = async (params = {}) => {
      const roleFaq = JSON.parse(localStorage.getItem('user')).user
      let type = 'all'
      if (roleFaq.role === 'school') {
        type = 'school'
      } else if (roleFaq.role === 'teacher') {
        type = 'teacher'
      }
      await context.root.$services.ApiServices.list('faq', {
        ...params,
        type,
      }).then(
        ({ data }) => {
          faqQiestions.value = data
          faqQuestions.value.push(data)
        },
        err => {
          console.error(err)
        },
      )
    }
    const searchHandler = data => {
      search.value = data
    }
    const contactDetails = [
      {
        icon: mdiPhone,
        contact: '+62 812-6403-1579',
        tagLine: 'We are always happy to help!',
      },
      {
        icon: mdiEmailOutline,
        contact: 'literasia@gmail.com',
        tagLine: 'Best way to get answer faster!',
      },
    ]

    watch(search, () => {
      if (search.value.length > 3) listFaq({ search: search.value })
    })
    onMounted(() => {
      listFaq()
    })

    return {
      faqQiestions,
      listFaq,
      faqQuestions,
      contactDetails,
      searchHandler,
      search,
      icons: {
        mdiMagnify,
        mdiHelpCircleOutline,
        mdiCurrencyUsd,
        mdiBriefcaseVariantOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/faq.scss';
</style>
